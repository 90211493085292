import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, action, runInAction, computed} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {
	ILeague,
	ILeagueDeleteUserParams,
	ILeagueUser,
	IGetJoinLeaguesParams,
	ILeagueUpdateParams,
	ILeagueInviteParams,
	IInvitingLeague,
	IGetMyLeaguesParams,
	ILeagueAddParams,
	ILeagueUpdateWithAvatarParams,
	IGetLeagueRankingParams,
	IRankingTeamObject,
} from "data/types/leagues";
import type {ILeaguesApiProvider} from "data/providers/api/leagues.api.provider";
import type {IPagination} from "data/types/common";
import {ModalType, RequestState} from "data/enums";
import type {IGetRankingsParams, IUserRanking} from "data/types/rankings";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IUserStore} from "data/stores/user/user.store";
import {IDictionary} from "data/stores/players/players.store";
import {ILineup} from "data/stores/team/team.store";
import {get, isEmpty} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {trackSentryErrors} from "data/utils";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {isAllTrue, isAnyTrue, isErrorStatusError} from "data/utils/helpers";

type IJJoinToLeaguesPagination = IPagination<{
	leagues: ILeague[];
}>;

type IMyLeaguesPagination = IPagination<{
	leagues: ILeague[];
}>;

type IRankingsPagination = IPagination<{
	rankings: IUserRanking[];
}>;

export interface IMatchupTeam {
	teamId: number;
	scoreFlow: IDictionary<number>;
	lineup: ILineup;
	bench: ILineup;
	emergency: number[];
	captainId: number;
	viceCaptainId: number;
	utilityId: number;
	isComplete: boolean;
	substitutions: number[];
}

export interface ILeaguesStore {
	get isLoaded(): boolean;
	get userLeagues(): IMyLeaguesPagination;
	get invitingLeagues(): IInvitingLeague[];
	get joinLeagues(): IJJoinToLeaguesPagination;
	get joinSearchLeagues(): IJJoinToLeaguesPagination;
	get joinedLeagueIds(): number[];
	get leagueUsers(): IPagination<{users: ILeagueUser[]}>;
	get SearchLeagueCodeOrName(): string;
	get leagueRankings(): IRankingsPagination;
	get currentUserRankingId(): number;
	get leagueUserId(): number | undefined;
	get matchupDisplay(): string;
	get matchupTeams(): IMatchupTeam[][];
	get matchupIndex(): number;
	get isShowNext(): boolean;
	get isShowPrevious(): boolean;
	get isSuccess(): boolean;
	get leagueTeamRankings(): IRankingTeamObject[];
	get regenerateList(): ILeague[];

	getLeagueById(leagueId: number): ILeague | undefined;
	getLeagueUserById(userId: number): ILeagueUser | undefined;
	fetchLeagues(props: IGetMyLeaguesParams): Promise<void>;
	fetchInvitingLeagues(): Promise<void>;
	fetchLeaguesToJoin(where: IGetJoinLeaguesParams): Promise<void>;
	leaveLeague(leagueId: number): Promise<void>;
	removeLeague(leagueId: number): Promise<void>;
	joinLeague(leagueCode: string): Promise<ILeague | undefined>;
	declineInviteLeague(leagueId: number): Promise<void>;
	getLeagueByCodeNoAuth(leagueCode: string): Promise<ILeague | undefined>;
	createLeague(
		params: FormData | ILeagueAddParams,
		isRegenerate?: boolean
	): Promise<ILeague | undefined>;
	updateLeague(params: ILeagueUpdateParams): Promise<ILeague | undefined>;
	updateLeagueWithAvatar(
		params: ILeagueUpdateWithAvatarParams | FormData
	): Promise<ILeague | undefined>;
	inviteLeague(params: ILeagueInviteParams): Promise<void>;
	fetchLeague(leagueId: number): Promise<ILeague | undefined>;
	deleteLeague(leagueId: number): Promise<void>;
	fetchLeagueTeamRankings({
		leagueId,
		roundId,
	}: {
		leagueId: number;
		roundId: number;
	}): Promise<void>;
	fetchLeagueUsers(
		leagueId: number,
		pageSize?: number,
		pageNumber?: number,
		newPage?: boolean | undefined
	): Promise<void>;
	clearLeagueUsers(): void;
	clearJoinLeagues(): void;
	clearJoinSearchLeagues(): void;
	removeLeagueUser(params: ILeagueDeleteUserParams): Promise<void>;
	isLeagueJoined(leagueId: number): boolean;
	setSearchLeagueCodeOrName(input: string): void;
	fetchLeagueRankings(props: IGetRankingsParams): Promise<void>;
	updateMatchupDisplay(val: string): void;
	fetchMatchups(roundId: number, leagueId: number): Promise<void>;
	fetchRegenerateList(): Promise<void>;
	removeRegenerateLeague(leagueId: number): Promise<void>;
	handleMatchupIndexChange(newIndex: number): void;
	setSelectedLeagueId(leagueId: number): void;
	getMatchupURL: (isNext: boolean) => string;
	clearRankings(): void;
}

@injectable()
export class LeaguesStore implements ILeaguesStore {
	@observable private _requestState = RequestState.IDLE;
	@observable private _userLeagues: IMyLeaguesPagination = {
		leagues: [],
		nextPage: false,
	};
	@observable private _invitingLeagues: IInvitingLeague[] = [];
	@observable private _joinLeagues: IJJoinToLeaguesPagination = {
		leagues: [],
		nextPage: false,
	};
	@observable private _joinSearchLeagues: IJJoinToLeaguesPagination = {
		leagues: [],
		nextPage: false,
	};
	@observable private _joinedLeagueIds: number[] = [];
	@observable private _leagueUsers: IPagination<{users: ILeagueUser[]}> = {
		users: [],
		nextPage: false,
	};
	@observable private _selectedLeagueId: number = 0;
	@observable private _searchLeagueCodeOrName = "";
	@observable protected _hasFetchedLeagueRankings: boolean = false;
	@observable private _leagueRankings: IRankingsPagination = {
		rankings: [],
		nextPage: false,
	};
	@observable private _currentUserRankingId: number = 0;
	@observable private _matchupDisplay: string = "h2h";
	@observable private _leagueMatchupTeams: IMatchupTeam[][] = [];
	@observable private _matchupIndex: number = 0;
	@observable private _leagueTeamRankings: IRankingTeamObject[] = [];
	@observable private _regenerateLeagues: ILeague[] = [];

	constructor(
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider,
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get SearchLeagueCodeOrName() {
		return this._searchLeagueCodeOrName;
	}
	@observable
	get matchupDisplay() {
		return this._matchupDisplay;
	}
	@observable
	get matchupTeams() {
		return this._leagueMatchupTeams;
	}
	@observable
	get matchupIndex() {
		return this._matchupIndex;
	}
	@observable
	get selectedLeagueId() {
		return this._selectedLeagueId;
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get selectedLeague() {
		return this.getLeagueById(this._selectedLeagueId);
	}

	@observable
	getMatchupURL = (isNext: boolean) => {
		const currentIndex = this.matchupIndex;
		const newIndex = isNext ? currentIndex + 1 : currentIndex - 1;
		const leagueRound = this._roundsStore.leagueFixtureSelectedRound;
		return `/leagues/${this._selectedLeagueId}/matchup/${leagueRound}/${newIndex}`;
	};
	@computed
	get isShowNext() {
		const currentRoundId = this._roundsStore.leagueFixtureSelectedRound;

		const roundFixtures = get(this.selectedLeague?.fixture, currentRoundId, []);
		const roundFixturesLength = roundFixtures.length;
		const currentIndex = this.matchupIndex;
		return roundFixturesLength > currentIndex + 1;
	}
	@computed
	get isShowPrevious() {
		const currentRoundId = this._roundsStore.leagueFixtureSelectedRound;
		const roundFixtures = get(this.selectedLeague?.fixture, currentRoundId, []);
		const roundFixturesLength = roundFixtures.length;
		const currentIndex = this.matchupIndex;
		return currentIndex + 1 <= roundFixturesLength && currentIndex !== 0;
	}

	get userLeagues() {
		return this._userLeagues;
	}

	get invitingLeagues() {
		return this._invitingLeagues;
	}

	get joinedLeagueIds() {
		return this._joinedLeagueIds;
	}

	get joinLeagues() {
		return this._joinLeagues;
	}

	get joinSearchLeagues() {
		return this._joinSearchLeagues;
	}

	get leagueUsers(): IPagination<{users: ILeagueUser[]}> {
		return this._leagueUsers;
	}

	get leagueRankings() {
		return this._leagueRankings;
	}

	get currentUserRankingId() {
		return this._currentUserRankingId;
	}

	get leagueUserId() {
		return this._userStore.user?.id;
	}

	get leagueTeamRankings() {
		return this._leagueTeamRankings;
	}

	get isSuccess() {
		return this._requestState === RequestState.SUCCESS;
	}

	get regenerateList() {
		return this._regenerateLeagues;
	}

	setSearchLeagueCodeOrName(input: string) {
		this._searchLeagueCodeOrName = input;
	}

	clearLeagueUsers(): void {
		this._leagueUsers = {
			users: [],
			nextPage: false,
		};
	}

	@action
	clearJoinLeagues(): void {
		this._joinedLeagueIds = [];
		this._joinLeagues = {
			leagues: [],
			nextPage: false,
		};
	}

	@action
	clearJoinSearchLeagues(): void {
		this._joinSearchLeagues = {
			leagues: [],
			nextPage: false,
		};
	}

	@action
	handleMatchupIndexChange(newIndex: number) {
		this._matchupIndex = newIndex;
	}

	getLeagueById(leagueId: number): ILeague | undefined {
		return this.userLeagues.leagues.find((league) => {
			return league?.id === leagueId;
		});
	}

	getLeagueUserById(userId: number): ILeagueUser | undefined {
		return this.leagueUsers.users.find((user) => {
			return user?.userId === userId;
		});
	}

	isLeagueJoined(id: number): boolean {
		return this.joinedLeagueIds.includes(id);
	}

	@action
	updateMatchupDisplay(val: string) {
		this._matchupDisplay = val;
	}

	async fetchMatchups(roundId: number, leagueId: number) {
		try {
			const {data} = await this._leaguesApiProvider.getLeagueMatchups({leagueId, roundId});
			if (data.success.matchUps) {
				this._leagueMatchupTeams = data.success.matchUps;
			}
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	async deleteLeague(leagueId: number) {
		try {
			this._requestState = RequestState.PENDING;
			const {data} = await this._leaguesApiProvider.delete({leagueId});
			if (data.success) {
				this._requestState = RequestState.SUCCESS;
			}
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	async fetchLeagueTeamRankings({roundId, leagueId}: IGetLeagueRankingParams) {
		try {
			this._requestState = RequestState.PENDING;

			const {data} = await this._leaguesApiProvider.getLeagueRankings({leagueId, roundId});

			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			const mappedWithUsers = data.success.rankings.map((ranking) => {
				const user = this.leagueUsers.users.find((user) => user.userId === ranking.userId);
				const isCurrentLeagueUser = ranking.userId === this.leagueUserId;
				return {
					...ranking,
					isCurrentLeagueUser,
					...user,
				};
			});
			runInAction(() => {
				this._leagueTeamRankings = mappedWithUsers;
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchLeagues({page, limit}: IGetMyLeaguesParams): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.myLeagues({page, limit});

			const myLeagues = data.success;

			const mergedArray = [...this._userLeagues.leagues, ...myLeagues.leagues];

			const filteredLeagueToJoin = mergedArray.filter(
				(obj, index) => mergedArray.findIndex((item) => item.code === obj.code) === index
			);

			const payload = page === 1 ? myLeagues.leagues : filteredLeagueToJoin;
			runInAction(
				() =>
					(this._userLeagues = {
						...myLeagues,
						leagues: [...payload],
					})
			);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchInvitingLeagues(): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.invitingLeagues();

			runInAction(() => {
				this._invitingLeagues = data.success.invites;
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchRegenerateList(): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.getRegenerateList();

			runInAction(() => {
				this._regenerateLeagues = data.success.leagues;
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async removeRegenerateLeague(leagueId: number): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.removeRegenerateLeague({leagueId});
			if (data.success) {
				runInAction(() => {
					this._regenerateLeagues = this._regenerateLeagues.filter(
						(league) => league.id !== Number(leagueId)
					);
				});
			}

			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchLeaguesToJoin(where: IGetJoinLeaguesParams) {
		try {
			this._requestState = RequestState.PENDING;
			const {data} = await this._leaguesApiProvider.showForJoinLeague(where);
			this._requestState = RequestState.SUCCESS;

			const leagueToJoin = data.success;

			if (leagueToJoin && !where.search) {
				const mergedArray = [...this._joinLeagues.leagues, ...leagueToJoin.leagues];

				const filteredLeagueToJoin = mergedArray.filter(
					(obj, index) =>
						mergedArray.findIndex((item) => item.code === obj.code) === index
				);
				runInAction(
					() =>
						(this._joinLeagues = {
							...leagueToJoin,
							leagues: [...filteredLeagueToJoin],
						})
				);
			}

			if (leagueToJoin && where.search) {
				this.clearJoinSearchLeagues();
				runInAction(
					() =>
						(this._joinSearchLeagues = {
							...leagueToJoin,
							leagues: [...this._joinSearchLeagues.leagues, ...leagueToJoin.leagues],
						})
				);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			console.log(err);
		}
	}

	@action
	async createLeague(
		params: FormData | ILeagueAddParams,
		isRegenerate?: boolean
	): Promise<ILeague | undefined> {
		try {
			const {data} = isRegenerate
				? await this._leaguesApiProvider.regenerateLeague(params)
				: await this._leaguesApiProvider.create(params);

			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				if (data?.success) {
					this._userLeagues.leagues.push(data?.success);
				}
			});

			return data?.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async getLeagueByCodeNoAuth(code: string): Promise<ILeague | undefined> {
		try {
			const {data} = await this._leaguesApiProvider.getLeagueByCodeNoAuth(code);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			return data?.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async updateLeague(params: ILeagueUpdateParams): Promise<ILeague | undefined> {
		try {
			const {data} = await this._leaguesApiProvider.updateLeague(params);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			runInAction(() => {
				if (data?.success) {
					this._userLeagues.leagues = this._userLeagues.leagues.map((league) => {
						if (league.id === data.success.id) {
							return data.success;
						}
						return league;
					});
				}
			});

			return data?.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async updateLeagueWithAvatar(
		params: ILeagueUpdateWithAvatarParams | FormData
	): Promise<ILeague | undefined> {
		try {
			const {data} = await this._leaguesApiProvider.updateLeagueWithAvatar(params);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			runInAction(() => {
				if (data?.success) {
					this._userLeagues.leagues = this._userLeagues.leagues.map((league) => {
						if (league.id === data.success.id) {
							return data.success;
						}
						return league;
					});
				}
			});

			return data?.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async leaveLeague(leagueId: number): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.leaveLeague({
				leagueId,
			});

			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				this._userLeagues.leagues = this._userLeagues.leagues.filter(
					(league) => league.id !== leagueId
				);
				this._joinedLeagueIds = this._joinedLeagueIds.filter((id) => id !== leagueId);
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async joinLeague(leagueCode: string): Promise<ILeague | undefined> {
		try {
			const {data} = await this._leaguesApiProvider.joinLeague({
				code: leagueCode,
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				if (data.success) {
					this._userLeagues.leagues.push(data.success);
					this._joinedLeagueIds.push(data.success.id);
				}
			});

			this.setSearchLeagueCodeOrName("");

			return data.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async declineInviteLeague(leagueId: number): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.declineInviteLeague({
				leagueId: leagueId,
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				if (data.success) {
					this._invitingLeagues = data.success.invites;
				}
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async inviteLeague(params: ILeagueInviteParams): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.inviteLeague(params);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchLeague(leagueId: number): Promise<ILeague | undefined> {
		try {
			const {data} = await this._leaguesApiProvider.getLeague({
				leagueId,
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				if (data) {
					this._userLeagues.leagues = [...this._userLeagues.leagues, data.success];
				}
			});

			return data.success;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async fetchLeagueUsers(
		leagueId: number,
		pageSize: number,
		pageNumber: number,
		newPage: boolean = false
	) {
		try {
			const {data} = await this._leaguesApiProvider.getLeagueUsers({
				leagueId,
				limit: pageSize,
				page: pageNumber,
			});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}

			runInAction(() => {
				if (data.success?.users) {
					const users = newPage ? this._leagueUsers?.users || [] : [];

					this._leagueUsers = {
						...data.success,
						users: pageNumber ? [...users, ...data.success.users] : data.success.users,
					};
				}
			});
			if (!isEmpty(this.leagueTeamRankings)) {
				const usersArr = data.success?.users;
				const mappedExtendedWithUserIds = usersArr.map((userObj) => {
					const existingTeamLadderObj = this.leagueTeamRankings.find(
						(team) => team.userId === userObj.userId
					);
					if (existingTeamLadderObj) {
						return {
							...existingTeamLadderObj,
							teamId: userObj.teamId,
							userId: Number(userObj.userId),
						};
					}
					return this.leagueTeamRankings[0];
				});
				runInAction(() => [(this._leagueTeamRankings = mappedExtendedWithUserIds)]);
			}
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async removeLeagueUser(params: ILeagueDeleteUserParams) {
		try {
			const {data} = await this._leaguesApiProvider.removeUserFromLeague(params);
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			const users = this._leagueUsers?.users || [];
			runInAction(() => {
				if (this._leagueUsers) {
					this._leagueUsers.users = users.filter((user) => user.userId !== params.userId);
				}
			});
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	async removeLeague(leagueId: number): Promise<void> {
		try {
			const {data} = await this._leaguesApiProvider.removeLeague({leagueId});
			if (data.errors[0]) {
				const error = data.errors[0].message;
				throw new Error(error);
			}
			return Promise.resolve();
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action
	setSelectedLeagueId(leagueId: number) {
		runInAction(() => {
			this._selectedLeagueId = leagueId;
		});
	}

	@action
	clearRankings() {
		runInAction(() => {
			this._leagueRankings = {
				rankings: [],
				nextPage: false,
			};
			this._leagueTeamRankings = [];
		});
	}

	catchError = (err: {message: string}) => {
		if (err) {
			const error = err.message;
			throw new Error(error);
		}
	};

	@action
	async fetchLeagueRankings(params: IGetRankingsParams): Promise<void> {
		this._requestState = RequestState.PENDING;

		try {
			const {data} = await this._rankingsApiProvider.rankings(params);
			this.catchError(data.errors[0]);
			const rankings = data.success;
			let mergedArray: Array<IUserRanking> = [];
			let filteredRanking: IUserRanking[] = [];
			let existingRankings = params.page === 1 ? [] : this._leagueRankings.rankings;
			const isUserInIncoming = rankings.rankings.find(
				(user) => user.userId === rankings.currentUserRanking.userId
			);
			const userBottomExistingAndNotIncoming = isAllTrue([
				existingRankings[existingRankings.length - 1]?.userId ===
					rankings.currentUserRanking.userId,
				existingRankings.length % 10 !== 0,
				!isUserInIncoming,
			]);

			if (isAnyTrue([Boolean(isUserInIncoming)])) {
				existingRankings = existingRankings.filter(
					(user) => user.userId !== rankings.currentUserRanking.userId
				);
			}
			if (!isEmpty(rankings.currentUserRanking)) {
				filteredRanking = rankings.rankings.map((item) => {
					item.isCurrentLeagueUser = item.userId === rankings.currentUserRanking?.userId;
					return item;
				});
			} else {
				filteredRanking = rankings.rankings;
			}

			const userIndex = existingRankings.findIndex(
				(user) => user.userId === rankings.currentUserRanking?.userId
			);
			const userInCurrentState = userIndex > -1;

			const checkRecentPayload = rankings.rankings.findIndex(
				(user) => user.userId === rankings.currentUserRanking?.userId
			);
			const isInRecentPayload = checkRecentPayload > -1;
			/* 
				if they were last element in rankings it means they werent in actual rankings
				need to filter from existing rankings if their:
				not in the incoming payload and exist in the rankings already
			*/

			if (
				isAllTrue([
					userInCurrentState,
					!isInRecentPayload,
					userBottomExistingAndNotIncoming,
				])
			) {
				existingRankings = existingRankings.filter(
					(user) => user.userId !== rankings.currentUserRanking?.userId
				);
			}
			mergedArray = [...existingRankings, ...filteredRanking];
			const lastElement = existingRankings[existingRankings.length - 1]?.userId;
			const isUserInMerged = Boolean(
				mergedArray.find((user) => user.userId === rankings.currentUserRanking?.userId)
			);
			const isUserInMergedNotBottom = isAllTrue([
				userInCurrentState,
				lastElement !== rankings.currentUserRanking?.userId,
			]);

			if (
				isAllTrue([
					isAnyTrue([!isUserInMergedNotBottom, !isUserInMerged]),
					!isUserInIncoming,
				])
			) {
				mergedArray.push({
					...rankings.currentUserRanking,
					isCurrentLeagueUser: true,
				});
			}

			await this._roundsStore.fetchRounds();
			await this._squadsStore.fetchSquads();
			runInAction(() => {
				this._leagueRankings = {
					...rankings,
					rankings: [...mergedArray],
				};
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this._requestState = RequestState.ERROR;
			const err = error as AxiosError<IApiResponse>;
			this.onError(err);
		}
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		const isSentryError = !isErrorStatusError(error);
		if (isSentryError) {
			trackSentryErrors(error, {}, "league store API error");
		}
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.response?.data.errors[0].message || "",
		});
	};
}
