import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {MatchStatus, RoundStatus} from "data/enums";
import {Dictionary, first, keyBy, last} from "lodash";
import {MATCHES_PER_ROUND} from "data/constants";

export const INDIGENOUS_ROUNDS = process.env.REACT_APP_INDIGENOUS_ROUNDS || "false";

export interface IMatch {
	id: number;
	venueName: string;
	venueId: number;
	date: string;
	homeSquadId: number;
	awaySquadId: number;
	homeSquadName: string;
	awaySquadName: string;
	homeScore: null;
	awayScore: null;
	status: MatchStatus;
	abbreviation: null;
}

/*
 * tournaments is match list
 * php8 does not allow word match
 */

export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	tournaments: IMatch[];
	byeSquads: number[];
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get scoreRound(): IRound | null;

	get selectedRound(): IRound | undefined;

	get isRoundOneMatchesComplete(): boolean;
	get isCurrentRoundIndigenous(): boolean;

	get roundsById(): Dictionary<IRound>;
	get activeOrCompletedRounds(): IRound[];
	get isCurrentRoundMatchesStarted(): boolean;
	get isRoundOneStarted(): boolean;
	get leagueFixtureSelectedRound(): number;
	get leagueLadderSelectedRound(): number;
	get lastCompletedRoundId(): number;
	get isRoundOneComplete(): boolean;
	get isCurrentRoundLive(): boolean;
	get isSeasonComplete(): boolean;
	fetchRounds(): Promise<void>;
	getIsSquadMatchStarted(squadId: number): boolean;
	setSelectedRound(roundId: number): void;
	setLeagueFixtureSelectedRound(roundId: number): void;
	setLeagueLadderSelectedRound(roundId: number): void;
	getIsSquadMatchForRoundStarted(squadId: number, roundId: number): boolean;
	getSquadMatchStatus(squadId: number, roundId: number): MatchStatus | undefined;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;
	@observable private _selectedRound: IRound | undefined = {
		id: 1,
		status: RoundStatus.Scheduled,
		startDate: "",
		endDate: "",
		tournaments: [],
		byeSquads: [],
	};
	@observable private _list: IRound[] = [];
	@observable private _leagueFixtureSelectedRound!: number;
	@observable private _leagueLadderSelectedRound: number = 0;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	setLeagueFixtureSelectedRound(roundId: number): void {
		this._leagueFixtureSelectedRound = roundId;
	}

	setLeagueLadderSelectedRound(roundId: number): void {
		this._leagueLadderSelectedRound = roundId;
	}

	get leagueFixtureSelectedRound(): number {
		return this._leagueFixtureSelectedRound;
	}

	get isCurrentRoundIndigenous() {
		return INDIGENOUS_ROUNDS === "true";
	}

	get isSeasonComplete() {
		return this.list.every((round) => round.status === RoundStatus.Complete);
	}

	get lastCompletedRoundId(): number {
		const completedRounds = this.list.filter((round) => round.status === RoundStatus.Complete);
		return last(completedRounds)?.id || 1;
	}

	get isRoundOneComplete() {
		return this.roundsById[1]?.status === RoundStatus.Complete;
	}

	get isCurrentRoundLive() {
		return this.currentRound?.status === RoundStatus.Playing;
	}

	get leagueLadderSelectedRound(): number {
		return this._leagueLadderSelectedRound;
	}

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	@action
	setSelectedRound = (roundId: number) => {
		const round = this.list.find((round) => round.id === roundId);
		this._selectedRound = round;
	};

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduleRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	get roundsById() {
		return keyBy(this.list, "id");
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	get selectedRound() {
		return this._selectedRound;
	}

	get activeOrCompletedRounds() {
		const activeOrCompleted = this.list.filter(
			(round) => round.status !== RoundStatus.Scheduled
		);
		const isAnyActive =
			this.list.filter((round) => round.status === RoundStatus.Playing).length > 0;
		const firstScheduledRound = isAnyActive
			? null
			: this.list.find((round) => round.status === RoundStatus.Scheduled);
		let returnArr = [...activeOrCompleted];
		if (firstScheduledRound) {
			returnArr = [...returnArr, firstScheduledRound];
		}
		return returnArr;
	}

	get isRoundOneStarted() {
		const roundOne = this._list.find((round) => round.id === 1);
		return roundOne?.status !== RoundStatus.Scheduled;
	}

	get isRoundOneMatchesComplete() {
		const roundOneMatches = this.list.find((round) => round.id === 1)?.tournaments;
		return (
			roundOneMatches?.filter((match) => match.status === MatchStatus.Complete).length ===
			MATCHES_PER_ROUND
		);
	}

	get isCurrentRoundMatchesStarted() {
		const roundMatches = this.currentRound?.tournaments;
		return (
			roundMatches?.filter((match) => match.status !== MatchStatus.Scheduled).length ===
			MATCHES_PER_ROUND
		);
	}

	getIsSquadMatchForRoundStarted(squadId: number, roundId: number) {
		const activeRound = this.roundsById[roundId];
		const isBye = activeRound?.byeSquads?.includes(squadId);
		if (isBye) {
			return false;
		}
		const squadMatch = activeRound?.tournaments.find((match) =>
			[match.homeSquadId, match.awaySquadId].includes(squadId)
		);
		return squadMatch?.status !== MatchStatus.Scheduled;
	}

	getIsSquadMatchStarted(squadId: number) {
		const activeRound = this.currentRound;
		const isBye = activeRound?.byeSquads?.includes(squadId);
		if (isBye) {
			return false;
		}
		const squadMatch = activeRound?.tournaments.find((match) =>
			[match.homeSquadId, match.awaySquadId].includes(squadId)
		);
		return squadMatch?.status !== MatchStatus.Scheduled;
	}

	getSquadMatchStatus(squadId: number, roundId: number) {
		const activeRound = this.roundsById[roundId];
		const isBye = activeRound?.byeSquads?.includes(squadId);
		if (isBye) {
			return MatchStatus.Scheduled;
		}
		const squadMatches = activeRound?.tournaments.filter((match) => {
			return [match.homeSquadId, match.awaySquadId].includes(squadId);
		});
		const isTwoGameWeek = squadMatches?.length === 2;
		if (isTwoGameWeek) {
			const completeMatches = squadMatches.filter(
				(match) => match.status === MatchStatus.Complete
			);

			const liveMatches = squadMatches.filter(
				(match) => match.status === MatchStatus.Playing
			);

			const isBothComplete = completeMatches.length === 2;
			const isOneComplete = completeMatches.length === 1;
			const isAnyLive = liveMatches.length > 0;
			if (isBothComplete) {
				return MatchStatus.Complete;
			} else if (isAnyLive) {
				return MatchStatus.Playing;
			} else if (isOneComplete) {
				return MatchStatus.Partial;
			} else {
				return MatchStatus.Scheduled;
			}
		}
		const squadMatch = activeRound?.tournaments.find((match) =>
			[match.homeSquadId, match.awaySquadId].includes(squadId)
		);
		return squadMatch?.status;
	}

	@action
	async fetchRounds() {
		let selectedRound = this.selectedRound;
		const {data} = await this._jsonProvider.rounds();
		const activeRound = data.find((e) => e.status === RoundStatus.Playing);

		const scheduledRound = first(data.filter((e) => e.status === RoundStatus.Scheduled));
		const currentRound = activeRound || scheduledRound || last(data);
		if (this.selectedRound?.id === 1) {
			selectedRound = currentRound;
		}

		runInAction(() => {
			this._list = data;
			this._selectedRound = selectedRound;
			this._leagueFixtureSelectedRound = currentRound?.id || 1;
		});
	}
}
