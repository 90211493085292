import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	type IPlayersStore,
	type IPlayer,
	IPoolFilters,
	IDictionary,
} from "data/stores/players/players.store";
import {IActiveSwap, ILineup, type ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import {PlayerPosition, RoundStatus, SortOrder, UTILITY} from "data/enums";
import {chain, get} from "lodash";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {
	type ISelectOption,
	type ITeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import {SelectChangeEvent} from "@mui/material";
import {ChangeEvent} from "react";

export interface IPlayerExtended extends IPlayer {
	isInTeam: boolean;
	displayPrice: string;
	canAddToTeam: boolean;
}

export interface ITeamLineupController extends ViewController {
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	resetPosition: () => void;
	setPosition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	sortPlayers: () => void;
	resetFilters: () => void;
	resetSearch: () => void;
	setFavourite: () => void;
	setFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setCaptain: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setViceCaptain: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setEmergency: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeFavouritePlayer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	setStatOption: (e: SelectChangeEvent) => void;
	swapPlayer: ({
		playerId,
		index,
		position,
		isBench,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
	}) => void;
	get playersById(): IDictionary<IPlayer>;

	get isUtilityBannerActive(): boolean;

	get fieldStat(): string;

	get filters(): IPoolFilters;

	get players(): IPlayerExtended[];
	get isSeasonComplete(): boolean;

	get order(): SortOrder;

	get isSubMode(): boolean;

	get squads(): ISquad[];
	get utilityId(): number | null;

	get positionsArray(): PlayerPosition[];
	get bench(): ILineup;
	get lineup(): ILineup;
	get isSelectedRoundBeforeActual(): boolean;
	get statsOptions(): ISelectOption[];
	get listStatsOptions(): ISelectOption[];
	get priceOptions(): ISelectOption[];
	get favouritePlayers(): number[];
	get captainId(): number | null;
	get viceCaptainId(): number | null;
	get activeSwap(): IActiveSwap;
	get renderPositionsArr(): {field: number[]; bench: number[]; position: PlayerPosition}[];
	get emergency(): number[] | null;
	get isTeamFirstRoundComplete(): boolean;
	get doubleScoreId(): number | null;
	get isTradeMode(): boolean;
	get isRoundLive(): boolean;
}

@injectable()
export class TeamLineupController implements ITeamLineupController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	@observable private _activeFieldStat: string = "avgPoints";

	private _statsOptions = [
		{val: "avgPoints", label: "Average", labelShort: "AVG"},
		{val: "totalPoints", label: "Total", labelShort: "Total"},
		{val: "roundPoints", label: "Week", labelShort: "Week"},
	];

	private _listStatsOptions = [
		{val: "seasonRank", label: "Overall \n Rank", labelShort: "RANK"},
		{val: "cost", label: "$ \n Value", labelShort: "VALUE"},
		{val: "highestScore", label: "Highest \n Wk Pts", labelShort: "HIGH"},
		{val: "avgPoints", label: "Average \n Pts", labelShort: "AVG"},
		{val: "lastRound", label: "Last \n Wk Pts", labelShort: "LAST"},
		{val: "totalPoints", label: "Total \n Pts", labelShort: "TOTAL", liveStat: true},
	];
	@observable
	get fieldStat() {
		return this._teamStore.fieldStat;
	}

	get isSubMode() {
		return this._teamStore.activeSwap.initialSwap.playerId !== 0;
	}

	get isTeamFirstRoundComplete() {
		return this._teamStore.isTeamStartRoundComplete;
	}
	get lineup() {
		return this._teamStore.team.lineup;
	}

	get bench() {
		return this._teamStore.team.bench;
	}

	get isSeasonComplete() {
		return this._roundsStore.isSeasonComplete;
	}

	get isSelectedRoundBeforeActual() {
		const selectedRoundId = this._roundsStore.selectedRound?.id || 1;
		const actualRoundId = this._roundsStore.currentRound?.id || 1;
		return selectedRoundId < actualRoundId;
	}

	@computed
	get listStatsOptions() {
		const currentRound = this._roundsStore.currentRound;
		const isSelectedCurrent = this._roundsStore.selectedRound?.id === currentRound?.id;
		const isCurrentLive = currentRound?.status === RoundStatus.Playing;
		const selectedRoundId = this._roundsStore.selectedRound?.id || 1;
		if ((isSelectedCurrent && isCurrentLive) || !isSelectedCurrent) {
			this._listStatsOptions[4] = {
				val: `scores[${selectedRoundId}]`,
				label: `Week ${selectedRoundId} Pts`,
				labelShort: `WK ${selectedRoundId}`,
				liveStat: true,
			};
		}
		return this._listStatsOptions;
	}

	get isUtilityBannerActive() {
		const actualRound = this._roundsStore.currentRound?.id || 1;
		const startRound = this.team.startRoundId;
		return actualRound - startRound < 3;
	}

	get doubleScoreId() {
		return this._teamStore.captainOrViceDouble;
	}

	get team() {
		return this._teamStore.team;
	}

	get squads(): ISquad[] {
		return this._squadsStore.list;
	}

	get emergency() {
		return this._teamStore.emergency;
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get statsOptions() {
		return this._statsOptions;
	}

	get priceOptions() {
		return this._teamBuilderStore.priceOptions;
	}

	get order() {
		return this._teamBuilderStore.order;
	}

	get favouritePlayers() {
		return this._playersStore.favouritePlayers;
	}

	get isRoundLive() {
		const selectedRound = this._roundsStore.selectedRound;
		const currentRound = this._roundsStore.currentRound;

		return (
			selectedRound?.id === currentRound?.id && currentRound?.status === RoundStatus.Playing
		);
	}

	get isTeamValidToBeSaved() {
		return this._teamStore.isTeamFullfilled() && this.team.isComplete;
	}

	get playersById() {
		return this._playersStore.playersById;
	}

	get utilityId() {
		return this._teamStore.utilityId;
	}

	get captainId() {
		return this._teamStore.captainId;
	}

	get viceCaptainId() {
		return this._teamStore.viceCaptainId;
	}

	get isTradeMode() {
		return this._teamStore.isTradeMode;
	}

	get players(): IPlayerExtended[] {
		const teamIds = this._teamStore.teamPlayerIds;

		const players = this._playersStore.getExtendedPlayers();
		return chain(players)
			.map((player) => {
				return {
					...player,
					isInTeam: teamIds.includes(player.id),
					displayPrice: this._playersStore.getPlayerDisplayPrice(String(player.cost)),
					// fixture: this._playersStore.getOpponents(
					// 	this._gameWeekStore.currentGameWeek,
					// 	player.squad
					// ),
					canAddToTeam: this._teamStore.canAddPlayerToTeam(player),
				};
			})
			.orderBy(
				[this.filters.stat, "lastName", "firstName"],
				[this._teamBuilderStore.order, "asc", "asc"]
			)
			.value();
	}

	get positionsArray(): PlayerPosition[] {
		return this._teamStore.positionsArray;
	}

	get activeSwap() {
		return this._teamStore.activeSwap;
	}

	get renderPositionsArr() {
		const lineup = this.lineup;
		const bench = this.bench;
		const defenders = {field: lineup.DEF, bench: bench.DEF, position: PlayerPosition.DEF};
		const midfielders = {field: lineup.MID, bench: bench.MID, position: PlayerPosition.MID};
		const rucks = {field: lineup.RUC, bench: bench.RUC, position: PlayerPosition.RUC};
		const forwards = {field: lineup.FWD, bench: bench.FWD, position: PlayerPosition.FWD};
		return [defenders, midfielders, rucks, forwards];
	}

	saveTeam = () => {
		this._teamStore.saveTeam();
	};
	@action
	removeFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "removePlayerFromTeam");
	};

	@action
	addToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamStore.addRemoveTeam(e.currentTarget.value, "addPlayerToTeam");
	};

	setCaptain = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		this._teamStore.setCaptain(Number(value));
	};

	setViceCaptain = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		this._teamStore.setViceCaptain(Number(value));
	};

	setEmergency = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		this._teamStore.setEmergency(Number(value));
	};
	sortPlayers = () => {
		this._teamBuilderStore.sortPlayers();
	};
	resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};
	resetSearch = () => {
		this._teamBuilderStore.resetSearch();
	};

	setFavourite = () => {
		this._teamBuilderStore.setFavourite();
	};

	setFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.setFavouritePlayer(playerId);
	};

	removeFavouritePlayer = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const playerId = Number(e.currentTarget.value);
		await this._playersStore.removeFavouritePlayer(playerId);
	};

	resetPosition = () => {
		this._teamBuilderStore.resetPosition();
	};

	setPosition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		this._teamBuilderStore.setPosition(e);
		const indexToAdd = e.currentTarget.dataset.playerindex;
		const isBench = e.currentTarget.dataset.isbench === "true";
		if (indexToAdd !== undefined) {
			this._teamStore.setAddIndex(Number(indexToAdd), isBench);
		}
	};
	updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		this._teamBuilderStore.updateFilter(e);
	};
	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		this._teamBuilderStore.updateSearch(e);
	};

	@action
	setStatOption = (e: SelectChangeEvent) => {
		this._teamStore.setStatOption(e.target.value);
	};

	swapPlayer = ({
		playerId,
		index,
		position,
		isBench,
	}: {
		playerId: number;
		index: number;
		position: PlayerPosition | UTILITY.UTIL;
		isBench: boolean;
	}) => {
		this._teamStore.swapPlayer({playerId, index, position, isBench});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		const isSeasonStarted =
			get(this._roundsStore.list, "[0].status", RoundStatus.Scheduled) !==
			RoundStatus.Scheduled;
		if (isSeasonStarted) {
			this._teamStore.setStatOption("roundPoints");
		}
	}
}
