import melbourne from "assets/img/clubLogos/svgs/7386.svg";
import bulldogs from "assets/img/clubLogos/svgs/7387.svg";
import freo from "assets/img/clubLogos/svgs/7886.svg";
import lions from "assets/img/clubLogos/svgs/7887.svg";
import giants from "assets/img/clubLogos/svgs/7889.svg";
import blues from "assets/img/clubLogos/svgs/8096.svg";
import collingwood from "assets/img/clubLogos/svgs/8097.svg";
import crows from "assets/img/clubLogos/pngs/8098.png";
import crowSvg from "assets/img/clubLogos/svgs/8098.svg";
import kangas from "assets/img/clubLogos/svgs/8466.svg";
import geelong from "assets/img/clubLogos/svgs/8467.svg";
import suns from "assets/img/clubLogos/pngs/8786.png";
import sunsSvg from "assets/img/clubLogos/svgs/8786.svg";
import eagles from "assets/img/clubLogos/svgs/8787.svg";
import tigers from "assets/img/clubLogos/svgs/8788.svg";
import saints from "assets/img/clubLogos/svgs/8796.svg";
import essendon from "assets/img/clubLogos/svgs/9406.svg";
import hawks from "assets/img/clubLogos/svgs/9407.svg";
import swans from "assets/img/clubLogos/svgs/9408.svg";
import swans_reverse from "assets/img/clubLogos/inverted/9408_reverse.png";
import power from "assets/img/clubLogos/svgs/9409.svg";
import portIndigenous from "assets/img/clubLogos/svgs/portIndigenous.svg";
import melbourneIndigenous from "assets/img/clubLogos/svgs/melbourneIndigenous.svg";
import westCoastEaglesIndigenous from "assets/img/clubLogos/pngs/WestCoastEaglesIndigenous.png";
import adelaideIndigenous from "assets/img/clubLogos/pngs/adelaideIndigenous.png";
import bye from "assets/img/aflw-fantasy-logo.svg";
import freoIndigenous from "assets/img/clubLogos/svgs/freoIndigenous.svg";
import {get} from "lodash";

const logoObject = {
	0: bye,
	7386: melbourne,
	7387: bulldogs,
	7886: freo,
	7887: lions,
	7889: giants,
	8096: blues,
	8097: collingwood,
	8098: crows,
	8466: kangas,
	8467: geelong,
	8786: suns,
	8787: eagles,
	8788: tigers,
	8796: saints,
	9406: essendon,
	9407: hawks,
	9408: swans,
	9409: power,
};

const indigenousLogos = {
	7386: melbourneIndigenous,
	7886: freoIndigenous,
	9409: portIndigenous,
	8098: adelaideIndigenous,
	8787: westCoastEaglesIndigenous,
};

export const checkReverseLogo = (id: number, playerModal?: boolean) => {
	if (id === 9408 && playerModal) {
		return swans_reverse;
	}

	if (id === 8098 && playerModal) {
		return crowSvg;
	}

	if (id === 8786 && playerModal) {
		return sunsSvg;
	}

	return get(logoObject, id);
};
export const getClubLogo = (id: number, isIndigenousRound: boolean, playerModal?: boolean) => {
	const isIndigenousLogoExist = Object.keys(indigenousLogos).includes(`${id}`);

	if (isIndigenousRound && isIndigenousLogoExist) {
		return get(indigenousLogos, id);
	}

	return checkReverseLogo(id, playerModal);
};

const teamPrimaryColorObj = {
	7386: "#061A33",
	7387: "#0039A6",
	7886: "#341356",
	7887: "#8B0042",
	7889: "#343433",
	8096: "#002E51",
	8097: "#000000",
	8098: "#0F1432",
	8466: "#0039A3",
	8467: "#002958",
	8786: "#BB0000",
	8787: "#002A5C",
	8788: "#000000",
	8796: "#E00034",
	9406: "#000000",
	9407: "#562500",
	9408: "#D11F2E",
	9409: "#0297B4",
};

export const getClubPrimaryColor = (id: number) => {
	return get(teamPrimaryColorObj, id, "#000000");
};
