import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import {theme} from "assets/theming/theme";
import {ThemeProvider} from "@mui/material";
import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {init, Integrations, BrowserTracing, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {Modals} from "views/components/modals/modals.component";
import {get} from "lodash";

const FILTERS = [
	// eslint-disable-next-line no-useless-escape
	/undefined is not an object \(evaluating \'window.navigation.addEventListener\'\)/gi,
	"window.navigation is undefined",
	"undefined is not an object (evaluating 'window.navigation.addEventListener')",
];

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "aflwfantasy.afl"],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
			"/telstra-furniture/resources/ver/scripts/telstra-bar.min.js",
		],
		ignoreErrors: [
			"Network Error",
			"Non-Error promise rejection captured with value: undefined",
			"UnhandledRejection",
			"Abort due to cancellation of share.",
			// eslint-disable-next-line no-useless-escape
			/undefined is not an object \(evaluating \'window.navigation.addEventListener\'\)/gi,
			"window.navigation is undefined",
		],
		sampleRate: 0.1,
		beforeSend(event) {
			// https://fanhubmedia.sentry.io/issues/5795373167/
			if (
				FILTERS.find((filter) => {
					const exceptionError = get(event.exception, "values[0].value", "");
					return event.message?.match(filter) || exceptionError.match(filter);
				})
			) {
				return null;
			}
			return event;
		},
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<InjectionProvider container={DIContainer}>
				<Bootstrap>
					<BrowserRouter basename={BASE_URL}>
						<Suspense fallback={<PagePreloader />}>
							{SecretGateController.IS_SECRET_PASSED ? (
								<Session>
									<RootRoutes />
								</Session>
							) : (
								<SecretGateRoutes />
							)}
						</Suspense>
						<Modals />
					</BrowserRouter>
				</Bootstrap>
			</InjectionProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
